<template>
  <el-card shadow="never" style="padding-bottom: 150px">
    <el-form label-width="220px">
      <TableTitleComponent title="作业消耗："></TableTitleComponent>
      <div class="box">
        <el-form-item label="【包材待回收库】包材数量：">
          <el-input-number v-model="formData.recycleAmount" size="small" :min="1" :step="1" step-strictly></el-input-number>
          <span class="tip" style="margin-left: 5px">提示：如果填了该数量，产出的总数量必须等于该数量</span>
        </el-form-item>
        <el-form-item label="【包材半成品库】包材数量：">
          <el-row class="material-row" v-for="semiObj in formData.semiList" :key="semiObj.nanoid">
            <el-select v-model="semiObj.packMaterialCode" size="small" filterable clearable placeholder="请选择包材编码">
              <el-option v-for="item in packMaterialCodeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
            </el-select>
            <el-input-number v-model="semiObj.amount" size="small" :min="1" :step="1" step-strictly></el-input-number>
            <el-button @click="removeOneSemi(semiObj.nanoid)" size="mini" type="danger" icon="el-icon-delete" circle style="margin-left: 5px"></el-button>
          </el-row>
          <el-row>
            <el-button size="mini" @click="addOneSemi()">添加一种半成品包材</el-button>
          </el-row>
        </el-form-item>
      </div>

      <TableTitleComponent title="作业产出：" style="margin-top: 30px"></TableTitleComponent>
      <div class="box">
        <el-form-item label="【包材报废库】包材数量：">
          <el-input-number v-model="formData.scrapAmount" size="small" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>
        <el-form-item label="【包材良品库】包材数量：">
          <el-row class="material-row" v-for="goodObj in formData.goodList" :key="goodObj.nanoid">
            <el-select v-model="goodObj.packMaterialCode" size="small" filterable clearable placeholder="请选择包材编码">
              <el-option v-for="item in packMaterialCodeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
            </el-select>
            <el-input-number v-model="goodObj.amount" size="small" :min="1" :step="1" step-strictly></el-input-number>
            <el-button @click="removeOneGood(goodObj.nanoid)" size="mini" type="danger" icon="el-icon-delete" circle style="margin-left: 5px"></el-button>
          </el-row>
          <el-row>
            <el-button size="mini" @click="addOneGood()">添加一种良品包材</el-button>
          </el-row>
        </el-form-item>
      </div>
      <el-row style="margin-top: 30px">
        <el-col :span="4" style="text-align: right">
          <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
        </el-col>
        <el-col :span="20">
          <MessageComponent ref="commitMessage"/>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import TableTitleComponent from "@/components/TableTitleComponent";
import MessageComponent from "@/components/MessageComponent";
import {nanoid} from 'nanoid'

export default {
  name: "Transfer",
  components: {MessageComponent, TableTitleComponent},
  data() {
    return {
      formData: {
        recycleAmount: undefined,
        scrapAmount: undefined,
        semiList: [],
        goodList: [],
      },
      packMaterialCodeList: [],
      loading: false
    }
  },
  created() {
    this.$axios.post('packMaterial/getAllPackMaterial').then(response => {
      let res = response.data;
      if (res.code !== 0) {
        return this.$message.error('获取包材编码数据源失败，' + res.message);
      }
      this.packMaterialCodeList = res.data || []
    }, error => {
      this.$message.error('获取包材编码数据源失败，' + error.message);
    })
  },
  methods: {
    addOneSemi() {
      this.formData.semiList = this.formData.semiList || []
      this.formData.semiList.push({
        nanoid: nanoid(),
        packMaterialCode: '',
        amount: undefined,
      })
    },
    removeOneSemi(nanoid) {
      let index = this.formData.semiList.findIndex(item => item.nanoid === nanoid);
      this.formData.semiList.splice(index, 1);
    },
    addOneGood() {
      this.formData.goodList = this.formData.goodList || []
      this.formData.goodList.push({
        nanoid: nanoid(),
        packMaterialCode: '',
        amount: undefined,
      })
    },
    removeOneGood(nanoid) {
      let index = this.formData.goodList.findIndex(item => item.nanoid === nanoid);
      this.formData.goodList.splice(index, 1);
    },
    checkInput() {
      //检查作业消耗
      if (this.formData.semiList.length > 0) {
        for (let i = 0; i < this.formData.semiList.length; i++) {
          let obj = this.formData.semiList[i]
          if (!obj.packMaterialCode) {
            this.$refs.commitMessage.showError(`半成品包材：第${i + 1}组包材的"包材编码"不能为空，请填写或删除该包材`);
            return false;
          }
          if (!obj.amount) {
            this.$refs.commitMessage.showError(`半成品包材：第${i + 1}组包材的"数量"不能为空，请填写或删除该包材`);
            return false;
          }
        }
      } else if (!this.formData.recycleAmount) {
        this.$refs.commitMessage.showError('作业消耗：待回收包材和半成品包材不能同时为空');
        return false;
      }

      //检查作业产出
      if (this.formData.goodList.length > 0) {
        for (let i = 0; i < this.formData.goodList.length; i++) {
          let obj = this.formData.goodList[i]
          if (!obj.packMaterialCode) {
            this.$refs.commitMessage.showError(`良品品包材：第${i + 1}组包材的"包材编码"不能为空，请填写或删除该包材`);
            return false;
          }
          if (!obj.amount) {
            this.$refs.commitMessage.showError(`良品品包材：第${i + 1}组包材的"数量"不能为空，请填写或删除该包材`);
            return false;
          }
        }
      } else if (!this.formData.scrapAmount) {
        this.$refs.commitMessage.showError('作业产出：报废包材和良品包材不能同时为空');
        return false;
      }

      //校验消耗数量和产出数量
      if (this.formData.recycleAmount) {
        let total = this.formData.recycleAmount
        let count = this.formData.scrapAmount || 0
        this.formData.goodList.forEach(item => count += item.amount)
        if (total != count) {
          this.$refs.commitMessage.showError(`"作业产出总数量"和"待回收包材数量"必须相等，待回收包材数量为${total}，作业产出总数量为${count}`);
          return false;
        }
      }

      return true
    },
    submit() {
      this.$refs.commitMessage.clear()
      if (!this.checkInput()) {
        return;
      }

      this.loading = true;
      this.$axios.post('storeAmount/transfer', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }
        this.$refs.commitMessage.showSuccess('提交成功');
        this.formData.recycleAmount = undefined
        this.formData.scrapAmount = undefined
        this.formData.semiList = []
        this.formData.goodList = []
      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError('提交失败，' + error.message);
      })

    }
  }
}
</script>

<style scoped>
.box {
  border: 1px dashed #ddd;
  padding: 10px
}

.material-row {
  padding-bottom: 5px;
}

.material-row .el-select {
  width: 150px;
  margin-right: 5px;
}
</style>